import * as Collapsible from '@radix-ui/react-collapsible';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { IconDescription } from '@/design-system/atoms/IconDescription';
import { LazyIconChevronDown } from '@/design-system/atoms/Icons/IconChevronDown/Lazy';
import { LazyIconClock } from '@/design-system/atoms/Icons/IconClock/Lazy';
import { Text } from '@/design-system/atoms/Text';
import Typography from '@/design-system/atoms/Typography';
import { normalizeWorkingHours } from '@/utils/normalizeWorkingHours';

import { workingHoursType } from '../../../custom-prop-types/workingHours';
import styles from './LocationOpenOrClosed.module.scss';

const formatTime = (workingHour) => {
    if (workingHour == null) {
        return 'Not specified';
    }

    const { is24Hours, closed, start, end, breakStart, breakEnd } = workingHour;

    if (is24Hours) {
        return 'Open 24 hours';
    }

    if (closed) {
        return 'Closed';
    }

    if (!start || !end) {
        return '';
    }

    const [openHours, openMinutes] = start ? start.split(':').map(Number) : [];
    const [closeHours, closeMinutes] = end ? end.split(':').map(Number) : [];
    const [breakStartHours, breakStartMinutes] = breakStart?.split(':').map(Number) ?? [];
    const [breakEndHours, breakEndMinutes] = breakEnd?.split(':').map(Number) ?? [];

    if (isNaN(openHours) || isNaN(closeHours) || isNaN(openMinutes) || isNaN(closeMinutes)) {
        console.error(`Invalid time format: ${JSON.stringify({ start, end })}`);
        return '';
    }

    if (
        (breakStartHours != null && isNaN(breakStartHours)) ||
        (breakStartMinutes != null && isNaN(breakStartMinutes)) ||
        (breakEndHours != null && isNaN(breakEndHours)) ||
        (breakEndMinutes != null && isNaN(breakEndMinutes))
    ) {
        throw new Error(`Invalid break time format: ${JSON.stringify({ breakStart, breakEnd })}`);
    }

    const hours = [
        [openHours, openMinutes],
        [breakStartHours, breakStartMinutes],
        [breakEndHours, breakEndMinutes],
        [closeHours, closeMinutes],
    ]
        .filter(([a, b]) => a != null && b != null)
        .map(([hours, minutes]) => {
            let mutableHours = hours;
            let anteOrPostMeridiem = '';

            if (hours < 12) {
                anteOrPostMeridiem = 'am';
            } else {
                anteOrPostMeridiem = 'pm';
            }

            mutableHours = mutableHours % 12 || 12;

            return `${mutableHours}:${minutes.toString().padStart(2, '0')}${anteOrPostMeridiem}`;
        });

    const firstTimes = hours.slice(0, 2);
    const secondTimes = hours.slice(2);

    if (secondTimes.length === 0) {
        return firstTimes.join(' - ');
    }

    return [firstTimes.join(' - '), secondTimes.join(' - ')].join(', ');
};

/**
 * This component is responsible to show if the location is open/close tag and the respective message
 */
const OpenOrClosed = (props) => {
    const { noBadge = false, is24Hours, isOpen, openUntil, openAt, waitTime, message } = props;

    return (
        <Typography
            tag={Typography.TAG.DIV}
            variant={Typography.VARIANT.T2}
            content={
                <div className={styles['location-open-or-closed']}>
                    {!noBadge && (
                        <div
                            className={classNames(
                                styles['location-open-or-closed__tag'],
                                is24Hours || isOpen
                                    ? styles['location-open-or-closed__tag--open']
                                    : styles['location-open-or-closed__tag--closed'],
                            )}
                        >
                            <Typography
                                variant={Typography.VARIANT.T2}
                                content={is24Hours ? 'Open 24 Hours' : isOpen ? 'Open' : 'Closed'}
                            />
                        </div>
                    )}
                    {message ? (
                        <Typography variant={Typography.VARIANT.T2} content={message} />
                    ) : (
                        <>
                            {!is24Hours && isOpen && openUntil && (
                                <Typography variant={Typography.VARIANT.T2} content={openUntil} />
                            )}
                            {(is24Hours || isOpen) && waitTime && (
                                <IconDescription iconComponent={LazyIconClock}>
                                    <Typography
                                        variant={Typography.VARIANT.T2}
                                        content={waitTime}
                                    />
                                </IconDescription>
                            )}
                            {!is24Hours && !isOpen && openAt && (
                                <Typography variant={Typography.VARIANT.T2} content={openAt} />
                            )}
                        </>
                    )}
                </div>
            }
        />
    );
};

/**
 * This component handle the logic of getting working hours and display it as a
 * open/close tag and the respective message.
 * If it receive the `showAll` props is will add a collapsed table with all working hours
 */
export const LocationOpenOrClosed = (props) => {
    const { workingHours, overrideMessage, temporarilyClosed, waitTime, showAll = false } = props;
    const { notSpecified, is24Hours, isOpen, openUntil, openAt } = React.useMemo(() => {
        return normalizeWorkingHours({ workingHours, temporarilyClosed });
    }, [workingHours, temporarilyClosed]);

    const tableWorkingHours = React.useMemo(() => {
        if (workingHours == null) {
            return {
                table: [],
                message: '',
            };
        }

        const today = new Date(new Date().toLocaleString('en', { timeZone: 'America/New_York' }));
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);
        today.setMilliseconds(0);
        const todayTimestamp = today.getTime();

        const weekdays = [
            ['sunday'],
            ['monday'],
            ['tuesday'],
            ['wednesday'],
            ['thursday'],
            ['friday'],
            ['saturday'],
        ];

        return weekdays.reduce(
            (acc, [key]) => {
                const temp = workingHours[key];

                const hasMessage = temp != null && temp.message != null && temp.message !== '';

                acc.table.push([
                    key.charAt(0).toUpperCase() + key.slice(1) + (hasMessage ? '*' : ''),
                    formatTime(temp),
                ]);

                if (!acc.message && hasMessage && todayTimestamp <= temp.timestamp) {
                    acc.message = temp.message;
                }

                return acc;
            },
            {
                table: [],
                message: '',
            },
        );
    }, [workingHours]);

    if (temporarilyClosed) {
        return <OpenOrClosed is24Hours={false} isOpen={false} message={overrideMessage} />;
    }

    if (overrideMessage != null && overrideMessage !== '') {
        return <OpenOrClosed noBadge message={overrideMessage} />;
    }

    if (notSpecified) {
        return null;
    }

    if (!showAll) {
        return (
            <OpenOrClosed
                is24Hours={is24Hours}
                isOpen={isOpen}
                openUntil={openUntil}
                openAt={openAt}
                waitTime={waitTime}
                message={workingHours.message}
            />
        );
    }

    return (
        <Collapsible.Root>
            <Collapsible.Trigger asChild>
                <button className={styles['location-open-or-closed__collapsible-button']}>
                    <OpenOrClosed
                        is24Hours={is24Hours}
                        isOpen={isOpen}
                        openUntil={openUntil}
                        openAt={openAt}
                        waitTime={waitTime}
                        message={workingHours.message}
                    />

                    <LazyIconChevronDown size="large" />
                </button>
            </Collapsible.Trigger>

            <Collapsible.Content asChild>
                <div className={styles['location-open-or-closed__working-hours']}>
                    {tableWorkingHours.table.map(([weekday, time]) => (
                        <div
                            key={weekday}
                            className={styles['location-open-or-closed__working-hours-line']}
                        >
                            <div>
                                <Typography variant={Typography.VARIANT.T2} content={weekday} />
                            </div>
                            <div>
                                <Typography
                                    variant={Typography.VARIANT.T2}
                                    content={time || 'Closed'}
                                />
                            </div>
                        </div>
                    ))}

                    {tableWorkingHours.message && (
                        <Text
                            variant={Text.VARIANT.T2}
                            className={styles['location-open-or-closed__working-hours-message']}
                            content={<>* {tableWorkingHours.message}</>}
                        />
                    )}
                </div>
            </Collapsible.Content>
        </Collapsible.Root>
    );
};

LocationOpenOrClosed.propTypes = {
    /**
     * Working hours
     */
    workingHours: workingHoursType,
    /**
     * Override message
     */
    overrideMessage: PropTypes.string,
    /**
     * Is temporarily closed
     */
    temporarilyClosed: PropTypes.bool,
    /**
     * Show wait time
     */
    waitTime: PropTypes.string,
    /**
     * Show table of hours
     */
    showAll: PropTypes.bool,
};
