import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './IconDescription.module.scss';

/**
 * This component is basically list item with a icon as a maker.
 *
 * Example:
 *
 * ```
 * // Short description
 *
 * 🍔 Nice hamburger
 *
 *
 * // Long description
 *
 * 🍔 Lorem ipsum dolor sit amet, consectetur adipiscing elit,
 *    sed do eiusmod tempor incididunt ut labore et dolore magna
 *    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
 *    ullamco laboris nisi ut aliquip ex ea commodo consequat.
 * ```
 */
export const IconDescription = (props) => {
    const { iconComponent: Icon, children, className } = props;

    return (
        <div className={classNames(styles['icon-description'], className)}>
            <div className={styles['icon-description__icon']}>
                <Icon size="small" />
            </div>
            <div className={styles['icon-description__description']}>{children}</div>
        </div>
    );
};

IconDescription.propTypes = {
    /**
     * Icon
     */
    iconComponent: PropTypes.elementType,
    /**
     * Description
     */
    children: PropTypes.node,
};
