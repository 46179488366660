const weekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

function getNextWeekdayIndex(
    workingHours,
    todayWeekdayIndex,
    nextWeekdayIndex = undefined,
    counter = 0,
) {
    let index = nextWeekdayIndex;

    if (todayWeekdayIndex === index) {
        return [];
    }

    const nextWorkingHours = workingHours[weekdays[index]];

    if (
        nextWorkingHours &&
        (nextWorkingHours.is24Hours || (nextWorkingHours.start && nextWorkingHours.end))
    ) {
        return [index, counter + 1];
    }

    if (index === weekdays.length - 1) {
        return getNextWeekdayIndex(workingHours, todayWeekdayIndex, 0, counter + 1);
    }

    return getNextWeekdayIndex(workingHours, todayWeekdayIndex, index + 1, counter + 1);
}

function formatOpenUntil(time) {
    const [hours, minutes] = time.split(':').map((v) => Number(v));

    let formattedHours = hours;
    let anteOrPostMeridiem = '';
    if (hours < 12) {
        anteOrPostMeridiem = 'am';
    } else {
        anteOrPostMeridiem = 'pm';
    }

    formattedHours = formattedHours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, '0');

    return `Until ${formattedHours}:${formattedMinutes}${anteOrPostMeridiem}`;
}

function formatOpenAt(nextDay, isBreakTime) {
    const capitalized =
        weekdays[nextDay.getDay()].charAt(0).toUpperCase() + weekdays[nextDay.getDay()].slice(1);

    let hours = nextDay.getHours();
    let anteOrPostMeridiem = '';
    if (hours < 12) {
        anteOrPostMeridiem = 'am';
    } else {
        anteOrPostMeridiem = 'pm';
    }
    hours = hours % 12 || 12;

    const minutes = nextDay.getMinutes().toString().padStart(2, '0');

    const time = `${hours}:${minutes}${anteOrPostMeridiem}`;
    return isBreakTime ? `Open at ${time}` : `Open ${capitalized} at ${time}`;
}

export function normalizeWorkingHours({
    workingHours,
    temporarilyClosed,
    today = new Date(new Date().toLocaleString('en', { timeZone: 'America/New_York' })),
}) {
    if (temporarilyClosed) {
        return { notSpecified: true };
    }

    if (workingHours == null) {
        return { notSpecified: true };
    }

    const weekday = weekdays[today.getDay()];
    const dailyWorkingHours = workingHours[weekday];

    if (dailyWorkingHours && dailyWorkingHours.is24Hours) {
        return {
            is24Hours: true,
        };
    }

    let dailyWorkingHoursNotClosed = false;
    if (dailyWorkingHours && dailyWorkingHours.end) {
        const [closeHours, closeMinutes] = dailyWorkingHours.end.split(':').map((v) => Number(v));
        const nextClose = new Date(today.getTime());
        nextClose.setHours(closeHours);
        nextClose.setMinutes(closeMinutes);
        nextClose.setSeconds(0);
        nextClose.setMilliseconds(0);

        dailyWorkingHoursNotClosed = today.getTime() < nextClose.getTime();
    }

    const isTodayValid =
        dailyWorkingHours &&
        dailyWorkingHours.start &&
        dailyWorkingHours.end &&
        dailyWorkingHoursNotClosed;

    let nextWeekdayIndex = today.getDay() + 1;
    if (nextWeekdayIndex > 6) {
        nextWeekdayIndex = 0;
    }

    const [nextDayIndex, addDays] = isTodayValid
        ? [today.getDay(), 0]
        : getNextWeekdayIndex(workingHours, today.getDay(), nextWeekdayIndex);

    if (nextDayIndex == null) {
        return { notSpecified: true };
    }

    const timestamp = today.getTime();
    const nextTimestamp = timestamp + addDays * 24 * 60 * 60 * 1000;

    const nextDailyWorkingHours = workingHours[weekdays[nextDayIndex]];
    const [openHours, openMinutes] = nextDailyWorkingHours.start.split(':').map((v) => Number(v));
    const [closeHours, closeMinutes] = nextDailyWorkingHours.end.split(':').map((v) => Number(v));

    const nextOpen = new Date(nextTimestamp);
    nextOpen.setHours(openHours);
    nextOpen.setMinutes(openMinutes);
    nextOpen.setSeconds(0);
    nextOpen.setMilliseconds(0);

    const nextClose = new Date(nextTimestamp);
    nextClose.setHours(closeHours);
    nextClose.setMinutes(closeMinutes);
    nextClose.setSeconds(0);
    nextClose.setMilliseconds(0);

    // Open
    if (today.getTime() >= nextOpen.getTime() && today.getTime() <= nextClose.getTime()) {
        if (dailyWorkingHours.breakStart && dailyWorkingHours.breakEnd) {
            const [breakCloseHours, breakCloseMinutes] = dailyWorkingHours.breakStart
                .split(':')
                .map((v) => Number(v));
            const [breakOpenHours, breakOpenMinutes] = dailyWorkingHours.breakEnd
                .split(':')
                .map((v) => Number(v));

            const breakClose = new Date(today.getTime());
            breakClose.setHours(breakCloseHours);
            breakClose.setMinutes(breakCloseMinutes);
            breakClose.setSeconds(0);
            breakClose.setMilliseconds(0);

            const breakOpen = new Date(today.getTime());
            breakOpen.setHours(breakOpenHours);
            breakOpen.setMinutes(breakOpenMinutes);
            breakOpen.setSeconds(0);
            breakOpen.setMilliseconds(0);

            if (today.getTime() < breakClose.getTime()) {
                return {
                    is24Hours: false,
                    isOpen: true,
                    openUntil: formatOpenUntil(dailyWorkingHours.breakStart),
                    openAt: undefined,
                };
            }

            if (today.getTime() >= breakClose.getTime() && today.getTime() <= breakOpen.getTime()) {
                return {
                    is24Hours: false,
                    isOpen: false,
                    openUntil: undefined,
                    openAt: formatOpenAt(breakOpen, true),
                };
            }
        }

        return {
            is24Hours: false,
            isOpen: true,
            openUntil: formatOpenUntil(nextDailyWorkingHours.end),
            openAt: undefined,
        };
    }

    return {
        is24Hours: false,
        isOpen: false,
        openUntil: undefined,
        openAt: formatOpenAt(nextOpen),
    };
}
