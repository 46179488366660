/**
 * Address props, to validate a address with a url
 */

import PropTypes from 'prop-types';

const addressPropType = PropTypes.shape({
    line1: PropTypes.string,
    line2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zipCode: PropTypes.string,
    latitude: PropTypes.string,
    longitude: PropTypes.string,
    googleMapsUrl: PropTypes.string,
});

export default addressPropType;
