/**
 * This function receives a obj with address data and format it.
 *
 * Example:
 *
 * ```js
 * const address: {
 *     line1: 'Address Line 1',
 *     line2: 'Address Line 2',
 *     city: 'City Name',
 *     state: 'ST',
 *     zipCode: '99999',
 * };
 *
 * const x = normalizeAddress(address);
 * console.log(x)
 * // -- Output
 * // Address Line 1
 * // Address Line 2
 * // City Name, ST 99999
 * ```
 *
 * @param {*} addressObj
 * @returns string
 */
export const normalizeAddress = (addressObj) =>
    [
        addressObj?.line1,
        addressObj?.line2,
        [[addressObj?.city, addressObj?.state].filter(Boolean).join(', '), addressObj?.zipCode]
            .filter(Boolean)
            .join(' '),
    ]
        .filter(Boolean)
        .join('\n');
