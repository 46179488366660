/**
 * Working hours type
 */

import PropTypes from 'prop-types';

const dailyWorkingHoursType = PropTypes.shape({
    is24Hours: PropTypes.bool,
    closed: PropTypes.bool,
    open: PropTypes.string,
    close: PropTypes.string,
    breakStart: PropTypes.string,
    breakEnd: PropTypes.string,
    timestamp: PropTypes.number,
    message: PropTypes.string,
});

export const workingHoursType = PropTypes.shape({
    message: PropTypes.string,
    sunday: dailyWorkingHoursType,
    monday: dailyWorkingHoursType,
    tuesday: dailyWorkingHoursType,
    wednesday: dailyWorkingHoursType,
    thursday: dailyWorkingHoursType,
    friday: dailyWorkingHoursType,
    saturday: dailyWorkingHoursType,
});
