
                    // ! automatically generated - Don't change this file manually
                    import React from 'react';
                    import load from '@loadable/component';

                    const LazyIcon = load(() => import('./index').then((m) => ({ default: m.IconChevronDown })), { ssr: false });

                    const ICON_SIZE = { small: '1rem', medium: '1.25rem', large: '1.5rem' };

                    export const LazyIconChevronDown = React.forwardRef((props, ref) => {
                        return (
                            <LazyIcon
                                ref={ref}
                                fallback={
                                    <span
                                        data-icon-suspense="chevron-down"
                                        className={props.className}
                                        style={{
                                            height: ICON_SIZE[props.size] ?? props.size ?? props.height ?? '1em',
                                            width: ICON_SIZE[props.size] ?? props.size ?? props.width ?? '1em',
                                        }}
                                    />
                                }
                                {...props} />
                        );
                    });

                    LazyIconChevronDown.displayName = 'LazyIconChevronDown';
                